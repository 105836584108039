export const latestNewsContainer = {
  padding: '6em 0',

  '.header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2em',

    '.title': {
      textTransform: 'uppercase',
      fontWeight: 500,
    },

    '.link-button': {
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: '1.3rem',
      width: 'fit-content',
      padding: 0,

      '.arrow-icon': {
        marginLeft: '0.5rem',
      },
    },
  },

  '@media (max-width: 991px)': {
    paddingTop: '2em',

    '& > div': {
      maxWidth: '100%',
    },
  },
};
