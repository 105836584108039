import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { getStorageValue } from '@veraio/core';
import { NotFound } from 'screens/public';
import { Col, MiniShoppingCartItem, Row, showApiError, TotalPricesCard, useWindowSize } from 'components';
import { useUserStore } from 'stores';
import { getAllUserAddresses, getOrdersDetails } from 'services';
import { ORDER_INFO, USER_DELIVERY_INFO, USER_INFO } from './config';
import { shoppingCartSummaryContainer } from './styles';

const storageKey = 'shopping-cart-summary';

const ShoppingCartSummary = () => {
  const user = useUserStore();
  const { getText } = useTranslations();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const summaryData = getStorageValue(storageKey);
  const [orderDetails, setOrderDetails] = useState({});
  const [addresses, setAddresses] = useState([]);

  const items = summaryData?.businesses?.flatMap((business) => business.items);
  const deliveryAddress = addresses?.find((el) => el.id === summaryData?.deliveryAddress);
  const shakerAccount = user?.userShakerInfo?.accounts?.find((el) => el.externalId === summaryData?.accountId);
  const lifeAccount = user?.accounts?.find((el) => el.id === summaryData?.accountId);
  const accountInfo = { ...lifeAccount, ...shakerAccount };
  const isMobile = windowWidth.width < 767;

  useEffect(() => {
    summaryData?.orderId && fetchOrderDetails();
  }, []);

  useEffect(() => {
    user?.userShakerInfo?.userAddresses && fetchUserAllAddresses();
  }, [user?.userShakerInfo?.userAddresses]);

  const fetchOrderDetails = async () => {
    const [res, err] = await getOrdersDetails(summaryData?.orderId);
    err ? showApiError(err) : setOrderDetails(res[0]);
  };

  const fetchUserAllAddresses = async () => {
    const params = { pageNumber: 0, pageSize: 100 };
    const [res, err] = await getAllUserAddresses(params);
    err ? showApiError(err) : setAddresses(res?.items);
  };

  return (
    <div css={shoppingCartSummaryContainer}>
      <h3 className="header-title">{getText('shoppingCartSummary')}</h3>

      {summaryData?.orderId ? (
        <Row gap={isMobile ? 4 : 32} className="shopping-cart-summary-info-container">
          <Col sm={12} md={12} lg={6}>
            <h4 className="subtitle">{getText('products')}</h4>

            <div className="items-container">
              <div className="mini-cart-items-container">
                {items?.map((product) => (
                  <MiniShoppingCartItem
                    key={product.dealId ?? product.id}
                    isSummary
                    notShowDelivery
                    product={product}
                  />
                ))}
              </div>
            </div>
          </Col>

          <Col sm={12} md={12} lg={6}>
            <div className="user-delivery-container">
              <div className="address-cart-container">
                <h4 className="subtitle">{getText('orderDetails')}</h4>
                <div className="order-cart">
                  {ORDER_INFO(orderDetails)?.map((el, ind) => (
                    <p className="info" key={ind}>
                      <b className="bold">{getText(el.label)}: </b>
                      {el.value}
                    </p>
                  ))}
                </div>

                <h4 className="subtitle">{getText('deliveryDetails')}</h4>
                <div className="order-cart">
                  {USER_DELIVERY_INFO(deliveryAddress)?.map((el, ind) => (
                    <p className="info" key={ind}>
                      <b className="bold">{getText(el.label)}: </b>
                      {el.value}
                    </p>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="subtitle">{getText('userInfo')}</h4>
                {USER_INFO(user?.userLifeInfo, accountInfo)?.map((el, ind) => (
                  <p className="info" key={ind}>
                    <b className="bold">{getText(el.label)}: </b>
                    {el.value}
                  </p>
                ))}
              </div>
            </div>

            <hr className="horizontal-line" />

            <h4 className="subtitle">{getText('priceDetails')}</h4>
            <TotalPricesCard shoppingCart={summaryData.shoppingCart} isSummary />
          </Col>
        </Row>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default ShoppingCartSummary;
