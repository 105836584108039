import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Col, LatestNews, Row, showError } from 'components';
import { ROUTES } from 'enums';
import { getNewsDetails } from 'services';
import { dateFormatNews, getImagePath, getTranslations, getTranslationData } from 'utils';
import { newsDetailsContainer } from './styles';

const NewsDetails = ({ history }) => {
  const { getText, language } = useTranslations();
  const params = useParams();
  const [newsData, setNewsData] = useState();
  const newsId = params?.newsId;

  useEffect(() => {
    language && fetchNews();
  }, [newsId, language?.code]);

  const fetchNews = async () => {
    const [res, err] = await getNewsDetails(newsId, {
      fields: `id,collectionId,categoryId,image,title,description,content,createdAt,chinaRestricted,${getTranslations(
        ['title', 'description', 'content'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);
    if (language?.code === 'zh' && res?.chinaRestricted) return history.push(ROUTES.News);

    setNewsData(res);
  };

  return (
    <div css={newsDetailsContainer(getImagePath(newsData))}>
      <section className="header">
        <Row justify="space-between" horizontalGap={16} className="content-row">
          <Col lg={6}>
            {newsData && (
              <>
                <h3 className="news-title">{getTranslationData(newsData, 'title', language?.code)}</h3>
                <p className="news-text">{getTranslationData(newsData, 'description', language?.code)}</p>
                {!isEmpty(newsData?.createdAt) && (
                  <p className="news-date">
                    {getText('published')}: {dateFormatNews(newsData?.createdAt)}
                  </p>
                )}
              </>
            )}
          </Col>
          <Col lg={6}>
            <div className="news-image" />
          </Col>
        </Row>
      </section>
      <div className="news-description-container">
        <section className="news-description">
          <div
            className="news-content"
            dangerouslySetInnerHTML={{ __html: getTranslationData(newsData, 'content', language?.code) }}
          />
        </section>
        <hr className="horizontal-line" />
        <LatestNews title="relatedNews" className="latest-news" />
      </div>
    </div>
  );
};

LatestNews.propTypes = {
  history: PropTypes.object,
};

export default NewsDetails;
