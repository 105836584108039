import { theme } from 'styles';

export const newsDetailsContainer = (bgImage) => ({
  '.header': {
    padding: '2em 11.25em',
    marginTop: '4em',
    backgroundColor: theme.secondary,
    marginBottom: '2em',

    '.news-title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,

      '@media (max-width: 767px)': {
        fontSize: 28,
      },

      '@media (max-width: 576px)': {
        fontSize: 22,
      },
    },

    '.news-text': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray800,
      marginBottom: 16,

      '@media (max-width: 767px)': {
        fontSize: 16,
      },
    },

    '.news-date': {
      color: theme.black,
      fontSize: 14,

      '@media (max-width: 767px)': {
        fontSize: 12,
      },
    },

    '.news-image': {
      backgroundColor: theme.secondaryDark,
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      aspectRatio: ' 16 / 9',
    },

    '@media (max-width: 1199px)': {
      padding: '2em 6.25em',
    },

    '@media (max-width: 991px)': {
      padding: '2em',

      '.content-row': {
        flexWrap: 'nowrap',
        flexDirection: 'column-reverse',
      },

      '.news-image': {
        marginBottom: 16,
      },
    },
  },

  '.news-description-container': {
    padding: '2em 11.25em',

    '.news-description': {
      marginBottom: '2em',

      '.news-content': {
        '& h1, h2, h3, h4, h5, h6, h7': {
          marginBottom: '0.2em',
          color: theme.secondaryDark,
          fontWeight: 400,
          textAlign: 'justify',
        },

        '& > p': {
          lineHeight: 1.7,
          textAlign: 'justify',
        },

        '& > ul': {
          marginLeft: '2em',
          lineHeight: 1.7,
          textAlign: 'justify',
        },

        '& > ol': {
          lineHeight: 1.7,
          textAlign: 'justify',
          margin: '1em',
        },

        '& > img': {
          maxWidth: '100%',
          height: 'auto',
        },

        '.video-container': {
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          paddingTop: '56.25%',
          margin: '1em 0',

          '& > iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
          },
        },
      },

      '@media (max-width: 767px)': {
        '&  > div ': {
          maxWidth: '100%',
        },
      },
    },

    '.horizontal-line': {
      height: 1,
      backgroundColor: theme.primary,
      margin: '4em 0 0 0',
    },

    '.latest-news': {
      padding: '1em 0',

      '.header': {
        padding: '2em',
      },
    },

    '@media (max-width: 1199px)': {
      padding: '2em 6.25em',
    },

    '@media (max-width: 991px)': {
      padding: '2em',
    },
  },
});
