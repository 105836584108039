import { getText } from '@veraio/strank';
import { Col, Image, Row, BecomeAffiliateCart, ProductsListingCard } from 'components';
import { useProductsStore } from 'stores';
import { productsContainer } from './styles';

const Products = ({ isMobile }) => {
  const productsAllData = useProductsStore();

  return (
    <>
      <div css={productsContainer}>
        <h4 className="title">{getText('naturalLineOfLiposomalProducts')}</h4>

        {productsAllData?.length > 0 &&
          productsAllData?.map((el, ind) => (
            <Row key={ind}>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="column">
                {el?.isRightImage && !isMobile ? (
                  <ProductsListingCard product={el} />
                ) : (
                  <Image src={el.listingImage} alt="product-image" className="product-image" />
                )}
              </Col>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} key={ind} className="column">
                {el?.isRightImage && !isMobile ? (
                  <Image src={el.listingImage} alt="product-image" className="product-image" />
                ) : (
                  <ProductsListingCard product={el} />
                )}
              </Col>
            </Row>
          ))}
      </div>
      <BecomeAffiliateCart />
    </>
  );
};

export default Products;
