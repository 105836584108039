import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { PriceLabel } from 'components';
import { totalPriceContainer } from './styles';

const TotalPricesCard = ({ shoppingCart, isSummary }) => {
  const { getText } = useTranslations();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);

  const cartTotal = shoppingCart.discountedTotalLocalPrice ?? shoppingCart.totalLocalPrice;
  const cartFiatTotal = shoppingCart.discountedTotalLocalPriceFiat ?? shoppingCart.totalLocalPriceFiat;
  const cartCryptoTotal = shoppingCart.discountedTotalPriceCrypto ?? shoppingCart.totalPriceCrypto;

  return (
    <div css={totalPriceContainer}>
      <div className="price-container">
        <p className="total-styles">{getText(isSummary ? 'totalPaid' : 'total')}:</p>
        <div className="total-styles">
          <PriceLabel
            fiat={cartFiatTotal}
            fiatOriginCurrency={selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code}
            fiatDesiredCurrency={selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code}
            crypto={cartCryptoTotal}
            cryptoDesiredCurrency={selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code}
            fiatOnly={false}
          />
        </div>
      </div>

      <div className="price-container">
        <p>{`${getText(isSummary ? 'regularPricePaid' : 'regularPrice')}: `}</p>
        <PriceLabel
          fiat={cartTotal}
          fiatOriginCurrency={selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code}
          fiatDesiredCurrency={selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code}
          total={cartTotal}
        />
      </div>
    </div>
  );
};

TotalPricesCard.propTypes = {
  shoppingCart: PropTypes.object,
  isSummary: PropTypes.bool,
};

export default TotalPricesCard;
