import { isNil, isEmpty } from 'lodash-es';

export const getImagePath = (data, isMobile) => {
  if (!data || isEmpty(data) || isNil(data)) return;
  const { collectionId, id, image, mobileImage } = data;
  if (!collectionId || !id || !image) return '';
  return `${apiUrls.pocketbaseUrl}/api/files/${collectionId}/${id}/${isMobile ? mobileImage : image}`;
};

export const filterModel = (data) => (!isEmpty(data) ? `(${Object.values(data).join(' && ')})` : null);

export const getTranslations = (fields, languageCode) => {
  if (languageCode === 'en') return '';
  return fields?.map((el) => `${el}_${languageCode}`).join();
};

export const getTranslationData = (data, key, languageCode) => {
  if (languageCode === 'en') return data?.[key];
  if (!isEmpty(data?.[`${key}_${languageCode}`])) return data?.[`${key}_${languageCode}`];
  return data?.[key];
};
