import { theme } from 'styles';

export const newsContainer = {
  marginTop: '4em',
  marginBottom: '4em',

  '.header-container': {
    width: '100%',
    backgroundColor: theme.secondary,
    padding: '4em 15.25em',

    '.title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
      textAlign: 'center',

      '@media (max-width: 767px)': {
        fontSize: 28,
      },

      '@media (max-width: 576px)': {
        fontSize: 22,
        padding: '0 1em',
      },
    },

    '.subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray800,
      textAlign: 'center',

      '@media (max-width: 767px)': {
        fontSize: 16,
      },

      '@media (max-width: 576px)': {
        padding: '0 1em 1em',
      },
    },

    '@media (max-width: 992px)': {
      padding: '4em',
    },

    '@media (max-width: 767px)': {
      paddingTop: '7em',
    },

    '@media (max-width: 576px)': {
      padding: 0,
      paddingTop: '7em',
    },
  },

  '.news-list-container': {
    padding: '4em 9.25em 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '.more-button': {
      marginTop: '3em',
    },

    '@media (max-width: 1199px)': {
      padding: '4em 4em 0',
    },

    '@media (max-width: 576px)': {
      padding: '2em 2em 0',
    },
  },
};
