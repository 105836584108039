import queryString from 'query-string';

export const getPocketBaseParams = (options) => {
  const { expand, fields, filter, page, perPage, skipTotal, sort } = options;

  return queryString.stringify(
    {
      ...(page && { page }),
      ...(perPage && { perPage }),
      ...(sort && { sort }),
      ...(filter && { filter }),
      ...(expand && { expand }),
      ...(fields && { fields }),
      ...(skipTotal && { skipTotal }),
    },
    { arrayFormat: 'index' },
  );
};
