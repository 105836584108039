import { theme } from 'styles';

export const productsContainer = {
  padding: '3em 19.25em',
  margin: '4em 0',

  '.title': {
    fontWeight: 400,
    fontSize: 54,
    color: theme.black,
    marginBottom: 48,
    textAlign: 'center',

    '@media (max-width: 576px)': {
      fontSize: 40,
      padding: '0.5em',
    },
  },

  '.column': {
    width: '100%',
    display: 'flex',

    '.product-image': {
      width: '100%',

      '@media (max-width: 767px)': {
        padding: 0,
        maxHeight: 600,
      },

      '@media (max-width: 576px)': {
        padding: 0,
      },
    },
  },

  '@media (max-width: 1199px)': {
    padding: '3em 9.25em',
  },

  '@media (max-width: 992px)': {
    padding: '3em 7.25em',
  },

  '@media (max-width: 767px)': {
    padding: '3em 5em',
  },

  '@media (max-width: 576px)': {
    padding: '3em 0em',
  },
};
