import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, CounterInput, Icon, Image, ProductPrice } from 'components';
import { removeProduct, updateProductQuantity, useUserStore } from 'stores';
import { cartItemContainer } from './styles';

const MiniShoppingCartItem = ({ product, isSummary, notShowDelivery }) => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useUserStore();
  const [isOutOfRange, setIsOutOfRange] = useState(false);
  const history = useHistory();

  const canDeliveryItem = product?.hasDeliveryToUserCountry;
  const canNotDelivery = !canDeliveryItem && isAuthenticated && !notShowDelivery;

  const handleQuantityChange = (quantity) => {
    quantity > product?.availableCouponsCount ? setIsOutOfRange(true) : setIsOutOfRange(false);

    Number(quantity) > 0 &&
      quantity < product?.availableCouponsCount &&
      updateProductQuantity(isAuthenticated, { ...product, quantity });
  };

  const handleDeleteProduct = () => {
    (product.dealId ?? product.id) && removeProduct(isAuthenticated, product.dealId ?? product.id);
  };

  return (
    <div css={cartItemContainer(isSummary, canNotDelivery)}>
      <div className="item-container">
        <Image src={product.miniCartImage} alt="product" className="product-image" />
        <div className="cart-info-container">
          <div className="title-delete-container">
            <div
              role="button"
              className="product-name"
              tabIndex={0}
              onClick={() => history.push({ pathname: `/products/${product.itemId}`, state: product })}>
              <p>{getText(product.productTitle)}</p>
            </div>
            {!isSummary && (
              <Button type="link" small onClick={handleDeleteProduct} className="delete-button">
                <Icon iconName="delete" size={20} color={canNotDelivery ? 'errorDark' : 'grayLight'} />
              </Button>
            )}
          </div>
          <div className="quantity-price-container">
            {!isSummary && (
              <CounterInput
                isOutOfRange={isOutOfRange}
                disabled={canNotDelivery}
                value={product.quantity}
                onChange={(val) => handleQuantityChange(val)}
              />
            )}
            <ProductPrice product={product} showFinalPrice />
          </div>
        </div>
      </div>
      {canNotDelivery && (
        <p className="not-delivery-text">{getText('thisProductCanNotBeDeliveredToSelectedCountry')}</p>
      )}
    </div>
  );
};

MiniShoppingCartItem.propTypes = {
  product: PropTypes.object,
  isSummary: PropTypes.bool,
  notShowDelivery: PropTypes.bool,
};

export default MiniShoppingCartItem;
