import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon } from 'components';
import { getImagePath, getTranslationData, dateFormatNews } from 'utils';
import { newsBoxContainer, newsImage } from './styles';

const NewsBox = ({ data, className }) => {
  const { getText, language } = useTranslations();

  return (
    <div css={newsBoxContainer} {...(className && { className })}>
      <div className="news-image-wrap">{<div css={newsImage(getImagePath(data))} />}</div>
      <div className="news-text-wrap">
        <div>
          <p className="news-date">{dateFormatNews(data?.createdAt)}</p>
          <h6 className="news-title">{getTranslationData(data, 'title', language?.code)}</h6>
          <p className="news-text">{getTranslationData(data, 'description', language?.code)}</p>
        </div>
        <Button
          type="link"
          className="link-button"
          linkTo={`/news/${data?.id}`}
          rightIcon={<Icon iconName="north_east" size={14} className="arrow-icon" />}>
          {getText('readMore')}
        </Button>
      </div>
    </div>
  );
};

NewsBox.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default NewsBox;
