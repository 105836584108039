import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import { setAuthConfig, AUTH_EVENTS_TYPES, renewSession } from '@oneecosystem/authenticate';
import { setDealshakerCoreConfig } from '@oneecosystem/dealshaker-core';
import { setStorageValue } from '@veraio/core';
import { setStrankConfig } from '@veraio/strank';
import { Global } from '@emotion/react';
import * as request from 'services/axios/makeRequest';
import { initializeUser } from 'stores';
import { globalCss } from 'styles';
import BaseApp from './screens/App';
import { LoadIcons } from 'components';

// Here you can place all of your wrappers inside for your app

const storageSummaryKey = 'shopping-cart-summary';
const storageShoppingCartKey = 'shopping-cart';

setAuthConfig({
  identity: { domain: authorizations.keycloakUrl, clientId: 'OneVita' },
  oneLifeDomain: apiUrls.lifeApiUrl,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: initializeUser,
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: initializeUser,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
    setStorageValue(storageSummaryKey, {});
    setStorageValue(storageShoppingCartKey, {});
  },
});

setDealshakerCoreConfig({
  dealshakerPortalApiDomain: apiUrls.dealshakerPortalApiUrl,
  oneLifeApiDomain: apiUrls.lifeApiUrl,
  storage: window.sessionStorage,
  requestInstance: {
    get: request.getReq,
    post: request.postReq,
    put: request.putReq,
    patch: request.patchReq,
    delete: request.deleteReq,
  },
});

setStrankConfig({ parseHtml: true, environment: strankEnvironment, storage: window.localStorage });

const App = () => {
  useEffect(() => {
    renewSession();
  }, []);

  return (
    <Router>
      <Global styles={globalCss} />
      <BaseApp />
      <LoadIcons />
    </Router>
  );
};

const root = createRoot(document.getElementById('one-vita-portal'));
root.render(<App />);
