import { theme } from 'styles';

export const newsBoxContainer = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.boxShadow,

  '.news-image-wrap': {
    padding: '1em',
  },

  '.news-text-wrap': {
    height: '100%',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    '.news-date': {
      fontSize: '0.8em',
      marginBottom: '0.8em',
    },

    '.news-title': {
      marginBottom: '0.8em',
    },

    '.news-text': {
      fontSize: '0.9em',
    },

    '.link-button': {
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: '1.3rem',
      width: 'fit-content',
      padding: 0,

      '.arrow-icon': {
        marginLeft: '0.5rem',
      },
    },
  },
};

export const newsImage = (url) => ({
  backgroundColor: theme.primaryDark,
  backgroundImage: `url(${url})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  aspectRatio: ' 16 / 9',
  width: '100%',
});
