import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Image } from 'components';
import { DELIVERY_FEE_DATA } from './config';
import { deliveryContainer } from './styles';

const Delivery = () => {
  const { getText } = useTranslations();
  const geoLocation = useLocations((locationsState) => locationsState.geoLocation);
  const cryptoSign = geoLocation?.isCryptoRestricted ? 'OESP' : 'OES';

  return (
    <div css={deliveryContainer}>
      <div className="header-container">
        <div className="title-container">
          <p className="title">{getText('standardDelivery')}</p>
          <Image
            src="https://oneecosystem.blob.core.windows.net/vita/apps/images/delivery-vita-icon.svg"
            className="delivery-icon"
          />
        </div>
        <p className="title-paragraph">{getText('deliveryToEuropeanCountriesWorkingDays')}</p>
        <p className="title-paragraph">{getText('deliveriesAreMadeFromMondayToFriday')}</p>
        <p className="title-paragraph">
          {getText('deliveryIsFreeOnAllOrdersOversAndLessThan', { overAmount: 300, lessThanAmount: 300 })}
        </p>
      </div>
      <div className="table-container">
        <div className="table-header">
          <p>{getText('country')}</p>
          <p>{getText('price')}</p>
        </div>
        {DELIVERY_FEE_DATA.map((el, ind) => (
          <div key={ind} className="table-header">
            <p className="table-rows">{el.countries}</p>
            <p className="table-rows">{el.price}€</p>
          </div>
        ))}
      </div>
      <div className="italic-info-container">
        <p className="italic-info">
          *{getText('priceOfDeliveryInEuropeWillBeReCalculated', { paymentMethods: `50% Cash, 50% ${cryptoSign}` })}
        </p>
        <p className="italic-info">
          *{getText('forAnyQuestionsAboutDeliveriesOutsideEuropePleaseContactOurTeam')}{' '}
          <a href="mailto: onevita@oneecosystem.eu" className="email-link">
            onevita@oneecosystem.eu
          </a>
        </p>
      </div>
    </div>
  );
};

export default Delivery;
