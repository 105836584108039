import { theme } from 'styles';

export const cartItemContainer = (isSummary, canNotDelivery) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1.3em',

  '.item-container': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.secondary,
    border: canNotDelivery ? `1px solid ${theme.errorDark}` : 'none',
    marginBottom: '0.5em',

    '.product-image': {
      position: 'relative',
      top: -10,
      maxWidth: '6.25rem',
      maxHeight: '11rem',
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
    },

    '.cart-info-container': {
      width: '100%',
      padding: '0.5em 1.5em',
      display: isSummary && 'flex',
      flexDirection: isSummary && 'column',
      justifyContent: isSummary && 'center',

      '.title-delete-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.6em',

        '.product-name': {
          marginRight: '1em',
          fontWeight: 600,
        },

        '.delete-button': {
          padding: 0,
        },
      },

      '.quantity-price-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.5em',

        '@media (max-width: 567px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
    },
  },

  '.not-delivery-text': {
    color: theme.errorDark,
    fontWeight: 500,
  },
});
