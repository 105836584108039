import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { MiniShoppingCart } from 'screens/public';
import { Button, Image, Languages, Icon, User } from 'components';
import { ROUTES } from 'enums';
import { useUserStore, useProductsStore } from 'stores';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  button,
  navBarHeaderContainer,
  navBarLeftSideContainer,
  navBarRightSideContainer,
  imageContainer,
} from './styles';

const NavBar = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const [active, setActive] = useState(null);
  const [showContainer, setShowContainer] = useState(false);
  const { isAuthenticated, isDisabled } = useUserStore();
  const productsAllData = useProductsStore();

  useEffect(() => {
    setActive(history.location?.pathname);
  }, [history.location?.pathname]);

  const handleProductClick = () => {
    history.push(ROUTES.Products);
    setActive(ROUTES.Products);
  };

  const handleSelectProduct = (event, product) => {
    event.stopPropagation();
    setShowContainer(false);
    history.push({ pathname: `/products/${product.itemId}`, state: product });
  };

  return (
    <header css={navBarHeaderContainer(ROUTES.Products === active)}>
      <div css={navBarLeftSideContainer}>
        <Logo
          onClick={() => {
            history.push(ROUTES.Home);
            setActive(ROUTES.Home);
          }}
        />
      </div>

      <div className="buttons-container">
        <div className="products">
          <div
            role="button"
            tabIndex={0}
            onClick={handleProductClick}
            className="product-button"
            onMouseEnter={() => setShowContainer(true)}
            onMouseLeave={() => setShowContainer(false)}>
            {getText('products')}

            {showContainer && (
              <div className="hovered-content-container">
                <h3 className="title">{getText('smallButMightyUnlockPower')}</h3>
                <div className="product-container">
                  {productsAllData?.length > 0 &&
                    productsAllData?.map((el) => (
                      <div
                        key={el.itemId}
                        className="product"
                        role="button"
                        tabIndex={0}
                        onClick={(event) => handleSelectProduct(event, el)}>
                        <Image src={el.imageNav} alt="product-image" css={imageContainer} />
                        <p className="product-name">{getText(el.navTitle)}</p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <Button
          type="link"
          linkTo={ROUTES.SkinCare}
          onClick={() => setActive(ROUTES.SkinCare)}
          css={button(ROUTES.SkinCare === active)}>
          {getText('skinCare')}
        </Button>
        <Button
          type="link"
          linkTo={ROUTES.BrandStory}
          onClick={() => setActive(ROUTES.BrandStory)}
          css={button(ROUTES.BrandStory === active)}>
          {getText('brandStory')}
        </Button>
        <Button
          type="link"
          linkTo={ROUTES.News}
          onClick={() => setActive(ROUTES.News)}
          css={button(ROUTES.News === active)}>
          {getText('news')}
        </Button>
        {/* // REMOVE TAG HIDE FOR FIRST VERSION
        {isAuthenticated ? (
          <Button
            type="link"
            linkTo={ROUTES.MyAffiliates}
            onClick={() => setActive(ROUTES.MyAffiliates)}
            css={button(ROUTES.MyAffiliates === active)}>
            {getText('myAffiliates')}
          </Button>
        ) : (
          <Button
            type="link"
            linkTo={ROUTES.BecomeAnAffiliate}
            onClick={() => setActive(ROUTES.BecomeAnAffiliate)}
            css={button(ROUTES.BecomeAnAffiliate === active)}>
            {getText('becomeAnAffiliate')}
          </Button>
        )} */}
      </div>
      <div css={navBarRightSideContainer}>
        <Languages className="languages-container" />
        {!isDisabled && <MiniShoppingCart />}

        {/* REMOVE TAG HIDE FOR FIRST VERSION
        <Icon iconName="apps" className="icon-nav-actions" /> */}

        {isAuthenticated ? (
          <User />
        ) : (
          <Icon iconName="login" className="icon-nav-actions" onClick={() => history.push(ROUTES.Login)} />
        )}
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
