import { theme } from 'styles';

export const languageIconContainer = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  padding: '2px 8px 2px 4px',
  margin: '24px 0',
  cursor: 'pointer',
  borderRadius: 22,
  color: theme.gray500,
  backgroundColor: theme.secondary,
  fontWeight: 500,
  fontSize: 16,
  width: 'fit-content',

  '.icon': {
    marginRight: 4,
  },
};

export const contentContainer = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
  border: `1px solid ${theme.secondary}`,
  color: theme.gray500,
  borderRadius: '0.25rem',
  width: 170,
  height: 125,
  overflow: 'auto',
  boxShadow: theme.boxShadow,
  padding: '8px 0',
};

export const item = (selectedLanguage) => ({
  padding: '8px 24px',
  backgroundColor: selectedLanguage && theme.secondary,
  color: selectedLanguage && theme.gray500,

  '&:hover': {
    backgroundColor: selectedLanguage ? theme.secondary : theme.gray100,
  },

  '& > h5': {
    color: theme.gray500,
  },
});
