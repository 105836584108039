import { theme } from 'styles';

export const shoppingCartSummaryContainer = {
  margin: '4em 0',
  padding: '0 6.25em',
  position: 'relative',

  '.header-title': {
    fontWeight: 500,
    padding: '2em 0',
    color: theme.gray900,
  },

  '.subtitle': {
    marginBottom: 16,
    fontWeight: 500,
  },

  '.shopping-cart-summary-info-container': {
    width: '100%',

    '.items-container': {
      height: '100%',
      marginBottom: '2em',

      '.mini-cart-items-container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& > div': {
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
      },
    },

    '.user-delivery-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      '.info': {
        color: theme.gray900,
        fontSize: '1.5rem',
        padding: '0.5em',
      },

      '.address-cart-container': {
        '.order-cart': {
          marginBottom: '2em',
        },
      },

      '@media (max-width: 567px)': {
        flexDirection: 'column',
      },
    },

    '.horizontal-line': {
      backgroundColor: theme.gray200,
      margin: '2em 0 3em',
    },
  },

  '@media (max-width: 567px)': {
    padding: '0 2em',
  },
};
