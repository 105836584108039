import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { startCase } from 'lodash-es';
import { getText } from '@veraio/strank';
import { Button, Col, Image, Row, BecomeAffiliateCart, DiscoverBeauty, showApiError } from 'components';
import { ALL_PRODUCTS_MOCK_DATA, PRODUCTS_SET_DATA, ROUTINE_PRODUCTS_DATA } from 'enums';
import { addProduct, useUserStore } from 'stores';
import { getProductDetails } from 'services';
import { skinCareContainer } from './styles';

const SkinCare = ({ isMobile }) => {
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  const history = useHistory();
  const productDetails = (product) => ALL_PRODUCTS_MOCK_DATA?.find((el) => el.itemId === product?.itemId);
  const [product, setProduct] = useState(PRODUCTS_SET_DATA);

  useEffect(() => {
    fetchProductDetail();
  }, []);

  const fetchProductDetail = async () => {
    const [res, err] = await getProductDetails(productNames.setOfFiveProducts);
    err?.data?.errors?.[0]?.code
      ? showApiError(err.data?.errors?.[0]?.code)
      : setProduct((prev) => ({ ...prev, ...res }));
  };

  const handleAddProductToCart = () => {
    addProduct(isAuthenticated, product);
    window.scrollTo(0, 0);
  };

  const informationColumn = (item) => (
    <Col xs={12} sm={12} md={5} lg={4} xl={4} className="products-info-container">
      {isMobile ? (
        <div className="mobile-product-title">
          <div className="step">{item.itemId}</div>
          <h5 className="title">{getText(item?.title)}</h5>
        </div>
      ) : (
        <>
          <h5 className="title">{getText(item?.title)}</h5>
          <hr className="horizontal-line" />
        </>
      )}
      <p className="description">{getText(item?.description)}</p>
      <Button
        type="secondary"
        className="product-button"
        onClick={() => history.push({ pathname: `/products/${item.itemId}`, state: productDetails(item) })}>
        {getText(item.buttonText)}
      </Button>
    </Col>
  );

  return (
    <div css={skinCareContainer}>
      <Image
        src={
          isMobile
            ? 'https://oneecosystem.blob.core.windows.net/vita/apps/images/five-step-mobile-image.png'
            : 'https://oneecosystem.blob.core.windows.net/vita/apps/images/routine-header-image.jpg'
        }
        alt="routine-header-image"
        className="header-image"
      />

      <div className="header-info-container">
        <h3 className="title">{getText('fiveStepSystemForYouthfulSkin')}</h3>
        <p className="subtitle">{getText('transformYourDailySkincare')}</p>
      </div>

      <div className="routine-steps-container">
        <h4 className="routine-title">{startCase(getText('learnRoutineBelow'))}</h4>
        <div className="routine-container">
          <Row gap={24} className="bordered-container">
            {ROUTINE_PRODUCTS_DATA.map((el, ind) => (
              <div key={ind}>
                {!isMobile && <div className="step">{el.itemId}</div>}
                {isMobile ? (
                  <div className="mobile-content-container">
                    {informationColumn(el)}
                    <Image src={el.image} alt="mobile" className="mobile-images" />
                  </div>
                ) : (
                  <div>
                    {el.isRightImage ? (
                      <div className="product-container">
                        <Col xs={12} sm={12} md={2} lg={2} xl={2} />
                        {informationColumn(el)}
                        <Col xs={12} sm={12} md={1} lg={1} xl={2} />
                        <Col xs={12} sm={12} md={4} lg={5} xl={4}>
                          <Image src={el.image} alt="image" className="image" />
                        </Col>
                      </div>
                    ) : (
                      <div className="product-container">
                        <Col xs={12} sm={12} md={2} lg={1} xl={2} />
                        <Col xs={12} sm={12} md={4} lg={5} xl={4}>
                          <Image src={el.image} alt="image" className="image" />
                        </Col>
                        <Col xs={12} sm={12} md={1} lg={2} xl={2} />
                        {informationColumn(el)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            <Button type="primary" className="buy-routine" onClick={handleAddProductToCart}>
              {getText('buyRoutine')}
            </Button>
          </Row>
        </div>
      </div>

      <hr className="horizontal-end-routine-line" />

      <DiscoverBeauty />
      <BecomeAffiliateCart />
    </div>
  );
};

export default SkinCare;
