import { theme } from 'styles';

export const deliveryContainer = {
  margin: '4em 0',
  padding: '3em 20.25em ',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '.header-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 600,
    marginBottom: '3em',

    '.title-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '3em',

      '.title': {
        fontSize: '3rem',
        margin: '0 0.3rem 1rem 0',
        textTransform: 'uppercase',
        color: theme.gray900,
      },

      '.delivery-icon': {
        width: 100,
        height: 80,
      },
    },

    '.title-paragraph': {
      color: theme.primaryLight,
      fontSize: '1.375rem',
      marginBottom: '1em',
      textAlign: 'center',
      fontWeight: 300,
    },
  },

  '.table-container': {
    width: '100%',
    marginBottom: '3em',
    maxWidth: 1200,

    '.table-header': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '1em',
      fontSize: '2rem',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: theme.black,

      '.table-rows': {
        fontSize: '1.5rem',
        textTransform: 'none',
      },
    },
  },

  '.italic-info-container': {
    fontStyle: 'italic',
    width: '100%',
    maxWidth: 1000,

    '.italic-info': {
      marginBottom: '1em',

      '.email-link': {
        margin: '0 4px',
        color: theme.primaryLight,
        textDecoration: 'underline',
      },
    },
  },

  '@media (max-width: 1199px)': {
    padding: '3em 6.25em',
  },

  '@media (max-width: 992px)': {
    padding: '3em',
  },
};
