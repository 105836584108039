import { useEffect, useState } from 'react';
import { isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, NewsBox, Button, Skeleton, showError } from 'components';
import { getAllNews } from 'services';
import { filterModel, getTranslations } from 'utils';
import { newsContainer } from './styles';

const News = () => {
  const { getText, language } = useTranslations();
  const [news, setNews] = useState(null);

  useEffect(() => {
    language && fetchNews(1);
  }, [language?.code]);

  const fetchNews = async (page) => {
    const filter = filterModel({
      categoryId: `categoryId="${newsCategoryId}"`,
      ...(language?.code === 'zh' && { chinaRestricted: 'chinaRestricted=false' }),
    });

    const [res, err] = await getAllNews({
      page,
      perPage: 20,
      sort: '-createdAt',
      filter,
      fields: `id,collectionId,image,title,description,createdAt,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);

    setNews((prev) =>
      isNil(prev) || page === 1
        ? res
        : {
            ...res,
            items: [...prev.items, ...res.items],
          },
    );
  };

  const handleMore = () => {
    const nextPage = news?.page + 1;
    fetchNews(nextPage);
  };

  return (
    <div css={newsContainer}>
      <div className="header-container">
        <h3 className="title">{getText('news')}</h3>
        <p className="subtitle">{getText('newsSubtitle')}</p>
      </div>

      <section className="news-list-container">
        <Row gap={12}>
          {isNil(news)
            ? Array(9)
                .fill()
                .map((_, i) => (
                  <Col md={4} key={i}>
                    <Skeleton width={300} height={300} />
                  </Col>
                ))
            : news?.items?.map((el) => (
                <Col key={el?.id} md={6} lg={4}>
                  <NewsBox data={el} />
                </Col>
              ))}
        </Row>

        {news?.page < news?.totalPages && (
          <Button onClick={handleMore} className="more-button">
            {getText('moreNews')}
          </Button>
        )}
      </section>
    </div>
  );
};

export default News;
