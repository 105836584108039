import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { initCurrenciesStore, initLocationsStore, useCurrencies } from '@oneecosystem/dealshaker-core';
import { isEmpty, setStorageValue } from '@veraio/core';
import { NavigationProvider, Notifications, useDeepEffect } from 'components';
import { ROUTES } from 'enums';
import { initCountries, initShoppingCartStore, initProductsStore, useShoppingCart, useUserStore } from 'stores';
import { addToShoppingCart, removeAllProductsFromShoppingCart } from 'services';
import { ForgotPassword, Login } from './authorization';
import { Routes } from './Routes';

const storageKey = 'shopping-cart-summary';

const App = () => {
  const { changeLanguage, allEnvLanguages } = useTranslations();
  const shoppingCart = useShoppingCart();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState.selectedCurrency);
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);
  const user = useUserStore();
  const history = useHistory();

  const leavePage = history?.location?.pathname !== ROUTES.ShoppingCartSummary;
  const cartItems = shoppingCart?.businesses?.flatMap((business) => business.items);

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useDeepEffect(() => {
    if (selectedCurrency?.rates) initShoppingCartStore();
  }, [isAuthenticated, selectedCurrency]);

  useEffect(() => {
    isAuthenticated && !isEmpty(user?.userShakerInfo) && removeAllProductsFromShoppingCart();

    setTimeout(() => {
      isAuthenticated && !isEmpty(user?.userShakerInfo) && cartItems?.length > 0 && addToShoppingCart(cartItems);
    }, 1500);
  }, [isAuthenticated]);

  useEffect(() => {
    leavePage && setStorageValue(storageKey, {});
  }, [leavePage]);

  useEffect(() => {
    initializeStores();
  }, []);

  const initializeStores = async () => {
    initCountries();
    initCurrenciesStore();
    initLanguagesStore();
    initLocationsStore();
    initTranslationsStore();
    await initProductsStore();
    initShoppingCartStore();
  };

  return (
    <NavigationProvider>
      <Notifications />
      <Switch>
        {!isAuthenticated && <Route exact path={ROUTES.Login} component={Login} />}
        {!isAuthenticated && <Route exact path={ROUTES.ForgotPassword} component={ForgotPassword} />}
        <Route component={Routes} />
      </Switch>
    </NavigationProvider>
  );
};

export default App;
