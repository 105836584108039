import { useEffect, useState } from 'react';
import { sub } from 'exact-math';
import { useTranslations } from '@veraio/strank';
import { isEmpty, isNil, setStorageValue, getStorageValue } from '@veraio/core';
import { useLocations } from '@oneecosystem/dealshaker-core';
import {
  AddressCart,
  Button,
  Col,
  Dropdown,
  EmptyShoppingCart,
  Icon,
  MiniShoppingCartItem,
  Row,
  showApiError,
  TotalPricesCard,
  WalletCart,
  useWindowSize,
} from 'components';
import { PAYMENTS_METHODS, ROUTES, USER_STATUS, WALLET_TYPE } from 'enums';
import {
  emptyShoppingCart,
  updateDeliveryAddress,
  updatePaymentMethods,
  useCountries,
  useShoppingCart,
  useUserStore,
} from 'stores';
import { createOrder, getAccountWallets, getAllUserAddresses } from 'services';
import { fadeInAnimation } from 'styles';
import { errorContent, shoppingCartContainer } from './styles';

const storageKey = 'shopping-cart-summary';
const cartStorageKey = 'shopping-cart';

const ShoppingCart = ({ history }) => {
  const shoppingCart = useShoppingCart();
  const user = useUserStore();
  const { isAuthenticated } = useUserStore();
  const countriesStore = useCountries();
  const { getText, language } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const geoLocation = useLocations((locationsState) => locationsState.geoLocation);
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const shoppingCartData = getStorageValue(cartStorageKey);

  const cartItems = shoppingCartData?.businesses?.flatMap((business) => business.items);
  const selectedDeliveryAddressId = cartItems?.length && cartItems[0].selectedUserAddressId;
  const dealShakerAccounts = user?.userShakerInfo?.accounts;
  const activeAccounts = user?.accounts?.filter((el) => el.status === USER_STATUS.Active);
  const currentAccount = activeAccounts?.length && activeAccounts?.find((el) => el.isCurrent);
  const preselectedAccount = !isNil(currentAccount) ? currentAccount : activeAccounts[0];
  const isMobile = windowWidth.width < 767;
  const countryData =
    countriesStore?.length &&
    countries?.map((el) => ({
      ...countriesStore?.find((country) => country.name === el.name),
      ...el,
    }));

  const [isLoading, setIsLoading] = useState(false);
  const [allWallets, setAllWallets] = useState([]);
  const [values, setValues] = useState({
    userAddresses: null,
    selectedAccountId: preselectedAccount?.id,
    selectedUserAddressId: selectedDeliveryAddressId ?? user?.userShakerInfo?.userAddresses[0]?.id,
  });

  const userSelectedCountry = values?.userAddresses?.find((el) => el.id === values?.selectedUserAddressId);
  const userSelectedCountryId = countryData?.find((el) => el.name === userSelectedCountry?.country)?.id;
  const cartItemsWithDeliveryFlag = cartItems?.map((item) => ({
    ...item,
    hasDeliveryToUserCountry: item.canBeDelivered ?? item?.deliveryToCountries?.includes(userSelectedCountryId), // REMOVE TAG  use canBeDelivered while gest checkout not merged, after that remove this
  }));

  const hasNotDeliveryProduct = cartItemsWithDeliveryFlag?.filter((el) => !el.hasDeliveryToUserCountry);

  const wallets =
    allWallets &&
    allWallets?.filter(
      (el) =>
        el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase() ||
        el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase(),
    );
  const oespWallet = allWallets?.find((el) => el.walletType === WALLET_TYPE.OESP);
  const fiatPrice = shoppingCart.discountedTotalLocalPriceFiat ?? shoppingCart.totalLocalPriceFiat;
  const cryptoPrice = shoppingCart.discountedTotalPriceCrypto ?? shoppingCart.totalPriceCrypto;
  const hasPriceError =
    cryptoPrice >
      sub(
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())?.balance ?? 0,
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.COIN.toLowerCase())?.blockedBalance ?? 0,
      ) ||
    fiatPrice >
      sub(
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())?.balance ?? 0,
        wallets?.find((el) => el.walletType.toLowerCase() === WALLET_TYPE.CASH.toLowerCase())?.blockedBalance ?? 0,
      );

  const disableButton =
    isNil(values?.selectedAccountId) ||
    hasPriceError ||
    isNil(values?.selectedUserAddressId) ||
    isLoading ||
    hasNotDeliveryProduct?.length > 0;

  useEffect(() => {
    user?.userShakerInfo?.userAddresses && fetchUserAllAddresses();
    setTimeout(() => {
      isAuthenticated &&
        user?.userShakerInfo?.userAddresses?.length > 0 &&
        updateDeliveryAddress(
          shoppingCart?.businesses?.map((business) => ({
            ...business,
            items: business?.items?.map((item) => ({
              ...item,
              selectedUserAddressId: values?.selectedUserAddressId ?? user?.userShakerInfo?.userAddresses[0]?.id,
              selectedBusinessAddressId: null,
            })),
          })),
          values?.selectedUserAddressId ?? user?.userShakerInfo?.userAddresses[0]?.id,
        );

      !isEmpty(user?.userShakerInfo) &&
        isAuthenticated &&
        updatePaymentMethods({
          cryptoPaymentMethod: geoLocation?.isCryptoRestricted
            ? PAYMENTS_METHODS.oespWallet
            : PAYMENTS_METHODS.oneWallet,
          cashPaymentMethod: PAYMENTS_METHODS.cashWallet,
        });
    }, 3000);
  }, [user?.userShakerInfo?.userAddresses]);

  useEffect(() => {
    setValues((prev) => ({ ...prev, selectedAccountId: preselectedAccount?.id }));
    user?.accounts?.length > 0 && fetchWalletsByAccountId(preselectedAccount?.id);
  }, [user?.accounts?.length]);

  const fetchWalletsByAccountId = async (accountId) => {
    const [res, err] = await getAccountWallets(accountId);
    err ? showApiError(err) : setAllWallets(res);
  };

  const handleSelectAccount = (val) => {
    fetchWalletsByAccountId(val.id);
    setValues((prev) => ({ ...prev, selectedAccountId: val.id }));
  };

  const handleUpdateUserAddress = (selectedUserAddressId) => {
    setValues((prev) => ({ ...prev, selectedUserAddressId }));

    updateDeliveryAddress(
      shoppingCart?.businesses?.map((business) => ({
        ...business,
        items: business?.items?.map((item) => ({ ...item, selectedUserAddressId, selectedBusinessAddressId: null })),
      })),
      selectedUserAddressId,
    );
  };

  const fetchUserAllAddresses = async () => {
    const params = { pageNumber: 0, pageSize: 100 };
    const [res, err] = await getAllUserAddresses(params);
    err ? showApiError(err) : setValues((prev) => ({ ...prev, userAddresses: res?.items }));
  };

  const handleCreateOrder = async () => {
    setIsLoading(true);
    const accountId = dealShakerAccounts?.find((el) => el.externalId === values.selectedAccountId)?.id;
    const [res, err] = await createOrder(accountId, { languageCode: language?.code });

    if (err) {
      setIsLoading(false);
      return showApiError(err);
    }
    setIsLoading(false);

    const summaryData = {
      ...shoppingCartData,
      shoppingCart: { ...shoppingCart },
      deliveryAddress: values?.selectedUserAddressId,
      accountId: values?.selectedAccountId,
      orderId: res[0],
    };

    setStorageValue(storageKey, summaryData);
    emptyShoppingCart();
    res && history.push(ROUTES.ShoppingCartSummary);
  };

  return (
    <div css={shoppingCartContainer}>
      <h3 className="header-title">{getText('shoppingCart')}</h3>

      {cartItems.length > 0 ? (
        <>
          <div className="address-cart-container">
            <h4 className="subtitle">{getText('address')}</h4>
            <AddressCart
              withAdd
              active={values?.selectedUserAddressId}
              addresses={values?.userAddresses ?? user?.userShakerInfo?.userAddresses}
              onChange={handleUpdateUserAddress}
              onAddAddress={fetchUserAllAddresses}
            />
          </div>

          <Row gap={isMobile ? 4 : 32} className="shopping-cart-info-container">
            <Col sm={12} md={12} lg={6}>
              <h4 className="subtitle">{getText('products')}</h4>

              <div className="items-container">
                <div className="mini-cart-items-container">
                  {cartItemsWithDeliveryFlag?.map((product) => (
                    <MiniShoppingCartItem key={product.dealId ?? product.id} product={product} />
                  ))}
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={6}>
              {activeAccounts?.length > 0 ? (
                <Dropdown
                  noClear
                  withSearch
                  label={getText('account')}
                  placeholder={getText('selectAccount')}
                  uniqueKey="id"
                  displayKey="displayName"
                  className="accounts-dropdown"
                  value={preselectedAccount}
                  options={activeAccounts}
                  onChange={handleSelectAccount}
                />
              ) : (
                <div className="error-container">
                  <Icon material iconName="error" className="error-icon" size={20} color="error" />
                  <p className="error-text">{getText('doNotHaveAccounts', { accountStatus: USER_STATUS.Active })}</p>
                </div>
              )}

              <div className="wallets-container">
                <p className="subtitle">{getText('yourBalance')}</p>
                <div className="wallet-cart-container">
                  {wallets?.map((el, ind) => (
                    <WalletCart key={ind} wallet={el} oespWallet={oespWallet} className="wallet-cart" />
                  ))}
                </div>
              </div>

              {hasPriceError && (
                <div css={[errorContent, fadeInAnimation()]}>
                  <Icon material iconName="error" className="error-icon" size={16} />
                  <span>{getText('notEnoughBalance')}</span>
                </div>
              )}

              <hr className="horizontal-line" />

              <h4 className="subtitle">{getText('price')}</h4>

              <TotalPricesCard shoppingCart={shoppingCart} />

              <Button disabled={disableButton} className="confirm-button" onClick={handleCreateOrder}>
                <Icon material iconName="lock" size={14} className="icon" />
                {getText('confirm')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <EmptyShoppingCart />
      )}
    </div>
  );
};

export default ShoppingCart;
