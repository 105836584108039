import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Image } from 'components';
import { ROUTES } from 'enums';
import image from 'assets/icons/icn-alert-error.svg';
import { mainContainer } from './styles';

const NotFound = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push(ROUTES.Home);
    }, 3000);
  }, []);

  return (
    <div css={mainContainer}>
      <Image src={image} alt="404 page" width="600px" />
    </div>
  );
};

export default NotFound;
