export const DELIVERY_FEE_DATA = [
  {
    countries: 'Slovenia',
    price: 11,
  },
  {
    countries: 'Austria, Czech Republic, Hungary, Germany, Slovakia',
    price: 22,
  },
  {
    countries:
      'Belgium, Denmark, Estonia, France, Ireland, Italy, Latvia, Lithuania, Luxemburg, Netherland ,Poland, Portugal, Spain, Sweden',
    price: 30,
  },
  {
    countries: 'Norway, Finland, Bulgaria, Romania',
    price: 40,
  },
];
