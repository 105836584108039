import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Col, Icon, NewsBox, Row, showError, useWindowSize } from 'components';
import { ROUTES } from 'enums';
import { getAllNews } from 'services';
import { filterModel, getTranslations } from 'utils';
import { latestNewsContainer } from './styles';

const LatestNews = ({ title, className }) => {
  const { getText, language } = useTranslations();
  const [news, setNews] = useState();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const isMobile = windowWidth.width < 767;

  useEffect(() => {
    language && fetchNews();
  }, [language?.code]);

  const fetchNews = async () => {
    const filter = filterModel({
      categoryId: `categoryId="${newsCategoryId}"`,
      ...(language?.code === 'zh' && { chinaRestricted: 'chinaRestricted=false' }),
      isActive: 'isActive=true',
    });

    const [res, err] = await getAllNews({
      perPage: 3,
      sort: '@random',
      filter,
      fields: `id,collectionId,image,title,description,createdAt,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
      skipTotal: true,
    });
    if (err) return showError(err?.data);

    setNews(res);
  };

  return (
    <div css={latestNewsContainer} {...(className && { className })}>
      <div className="header">
        <h4 className="title">{getText(title)}</h4>
        <Button
          type="link"
          className="link-button"
          linkTo={ROUTES.News}
          rightIcon={<Icon iconName="north_east" size={14} className="arrow-icon" />}>
          {getText('seeAll')}
        </Button>
      </div>

      <Row gap={isMobile ? 0 : 12} verticalGap={isMobile ? 12 : 0}>
        {news?.items?.map((el) => (
          <Col md={4} key={el?.id} className="news-box-item">
            <NewsBox data={el} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

LatestNews.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default LatestNews;
