import { flatten } from 'lodash-es';
import { deepEqual, isArray, isFunction, setStorageValue, getStorageValue } from '@veraio/core';
import { calculateShoppingCart } from '@oneecosystem/dealshaker-core';
import { getText } from '@veraio/strank';
import { createStore, useStore, showApiError, showSuccess } from 'components';
import { DELIVERY_TYPE } from 'enums';
import {
  addToShoppingCart,
  updateShoppingCartProductQuantity,
  updateShoppingCartDelivery,
  updateShoppingCartPaymentMethods,
  removeProductFromShoppingCart,
  removeAllProductsFromShoppingCart,
} from 'services';

const storageKey = 'shopping-cart';

const initState = {
  cashPaymentMethodId: null,
  cryptoPaymentMethodId: null,
  businesses: [],
};

const shoppingCartStore = createStore(initState);

export const initShoppingCartStore = () => {
  if (!getStorageValue(storageKey)) setStorageValue(storageKey, initState);

  setShoppingCart(getStorageValue(storageKey));
};

const setShoppingCart = (data) => {
  const oldState = shoppingCartStore.getState();
  const newState = calculateShoppingCart(isFunction(data) ? data(oldState) : data);

  !deepEqual(newState, oldState) && shoppingCartStore.setState(newState);
};

export const emptyShoppingCart = () => {
  setShoppingCart(initState);
  setStorageValue(storageKey, initState);
};

export const addProduct = async (isAuthenticated, item) => {
  const oldState = shoppingCartStore.getState();

  const itemToAdd = {
    ...item,
    quantity: 1,
    selectedBusinessAddressId: null,
    selectedUserAddressId: null,
    title: [...item.dealDetails],
  };

  const businessToAdd = {
    businessId: item.businessId,
    businessName: item.businessName,
    items: [itemToAdd],
    businessAddresses: item.addresses,
    promoCode: null,
  };

  if (isAuthenticated) {
    const [, err] = await addToShoppingCart(isArray(item) ? item : [item]);
    if (err) return showApiError(err);
  }

  const newState = {
    ...oldState,
    businesses: oldState.businesses.find((el) => el.businessId === item.businessId)
      ? oldState.businesses.map((business) =>
          business.businessId === item.businessId
            ? {
                ...business,
                items: business.items.find((el) => el.dealId === item.id)
                  ? business.items.map((el) =>
                      el.dealId === item.dealId ? { ...item, quantity: el.quantity + 1 } : el,
                    )
                  : business.items.concat(itemToAdd),
              }
            : business,
        )
      : oldState.businesses.concat(businessToAdd),
  };

  setShoppingCart(newState);
  setStorageValue(storageKey, newState);
  showSuccess(getText('productSuccessfullyAdded'));
};

export const updateProductQuantity = async (isAuthenticated, item) => {
  const oldState = shoppingCartStore.getState();

  if (isAuthenticated) {
    const [, err] = await updateShoppingCartProductQuantity(item);
    if (err) return showApiError(err);
  }

  const newState = {
    ...oldState,
    businesses: oldState.businesses.map((business) => ({
      ...business,
      items: business.items.map((product) =>
        product.id === item.id ? { ...product, quantity: item.quantity } : product,
      ),
    })),
  };

  setStorageValue(storageKey, newState);
  setShoppingCart(newState);
};

export const updateDeliveryAddress = async (newBusinesses, selectedUserAddressId) => {
  const oldState = shoppingCartStore.getState();

  const [res, err] = await updateShoppingCartDelivery(newBusinesses.flatMap((el) => el.items));
  if (err) return showApiError(err);

  const deliveryCountries = res?.businesses?.map((business) =>
    business.items?.map((el) => ({
      dealId: el.dealId,
      deliveryCountryIds: el.deliveryCountryIds,
      canBeDelivered: el.canBeDelivered, // REMOVE TAG while gest checkout not merged, after that remove this
    })),
  );

  const newState = {
    ...oldState,
    businesses: oldState.businesses.map((business) => ({
      ...business,
      items: business.items.map((item) => ({
        ...item,
        selectedUserAddressId,
        deliveryToCountries: flatten(deliveryCountries)?.find((el) => el.dealId === item.id)?.deliveryCountryIds,
        canBeDelivered: flatten(deliveryCountries)?.find((el) => el.dealId === item.id)?.canBeDelivered, // REMOVE TAG while gest checkout not merged, after that remove this
      })),
    })),
  };

  setShoppingCart(newState);
  setStorageValue(storageKey, newState);
};

export const resetDeliveryAddress = () => {
  const oldState = shoppingCartStore.getState();

  const newState = {
    ...oldState,
    businesses: oldState.businesses.map((business) => ({
      ...business,
      items: business.items.map((item) => ({
        ...item,
        selectedBusinessAddressId: null,
        selectedUserAddressId: null,
        guestAddress: null,
        deliveryTypeId: DELIVERY_TYPE.delivery,
      })),
    })),
  };

  setShoppingCart(newState);
  setStorageValue(storageKey, newState);
};

export const updatePaymentMethods = async (payments) => {
  const oldState = shoppingCartStore.getState();

  const [res, err] = await updateShoppingCartPaymentMethods({
    cashPaymentMethod: oldState.cashPaymentMethodId,
    cryptoPaymentMethod: oldState.cryptoPaymentMethodId,
    ...payments,
  });

  if (err) showApiError(err);

  const newState = {
    ...oldState,
    cashPaymentMethod: res.cashPaymentMethod,
    cryptoPaymentMethod: res.cryptoPaymentMethod,
  };

  setShoppingCart(newState);
  setStorageValue(storageKey, newState);
};

export const removeProduct = async (isAuthenticated, id) => {
  const oldState = shoppingCartStore.getState();

  if (isAuthenticated) {
    const [, err] = await removeProductFromShoppingCart(id);
    if (err) return showApiError(err);
  }

  const newState = {
    ...oldState,
    businesses: oldState.businesses.map((business) => ({
      ...business,
      items: business.items.filter((product) => product.id !== id),
    })),
  };

  setStorageValue(storageKey, newState);
  setShoppingCart(newState.businesses.length ? newState : initState);
};

export const removeAllProducts = async (isAuthenticated) => {
  if (isAuthenticated) {
    const [, err] = await removeAllProductsFromShoppingCart();
    if (err) return showApiError(err);
  }

  emptyShoppingCart();
};

export const useShoppingCart = (callback) => useStore(shoppingCartStore, callback);
