import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import { Button, Icon, PriceLabel, Tooltip } from 'components';
import { DEFAULT_CURRENCY } from 'enums';
import { addProduct, useUserStore } from 'stores';
import { productCartContainer, contentInfoTooltipContainer } from './styles';

const ProductsListingCard = ({ product }) => {
  const history = useHistory();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const isAuthenticated = useUserStore((userState) => userState.isAuthenticated);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={price?.discountedPriceFiat ?? price?.priceFiat}
        fiatOriginCurrency={price?.currencyCode}
        fiatDesiredCurrency={DEFAULT_CURRENCY.code}
        fiatCrypto={price?.discountedPriceCrypto ?? price?.priceCrypto}
        percentage={price?.percentRatio}
        total={price?.discountedPrice ?? price?.price}
      />
    );

  return (
    <div css={productCartContainer}>
      <h3 className="product-title">{getText(product?.productTitle)}</h3>
      <div className="price-icon-quantity-container">
        <p className="product-price">
          {printPrice({
            fiat: Number(product?.discountedPrice ?? product?.price),
            fiatOriginCurrency: product?.currencyCode,
            fiatDesiredCurrency: selectedCurrency?.code ?? DEFAULT_CURRENCY.code,
            total: product?.discountedPrice ?? product?.price,
          })}
        </p>
        <Tooltip
          content={convertedToLocalCurrencyPrice(product)}
          placement="bottom"
          noArrow
          contentClassName={contentInfoTooltipContainer}
          className="info-tooltip">
          <Icon iconName="info" size={16} className="icon" />
        </Tooltip>
        <p className="product-quantity">
          {product?.numberOfQuantity
            ? getText('quantityMl', { quantity: product.quantityMl, numberOfQuantity: product.numberOfQuantity })
            : getText('ml', { quantity: product.quantityMl })}
        </p>
      </div>
      <p className="product-description">{getText(product?.description)}</p>
      <div className="action-container">
        <Button type="default" className="button-default" onClick={() => addProduct(isAuthenticated, product)}>
          {getText('buyNow')}
        </Button>
        <Button
          type="link"
          className="button-link"
          onClick={() => history.push({ pathname: `/products/${product.itemId}`, state: product })}>
          {getText('learnMore')}
        </Button>
      </div>
    </div>
  );
};

ProductsListingCard.propTypes = {
  product: PropTypes.object,
};

export default ProductsListingCard;
